<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid style="max-width: 1600px">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Search <span style="padding-left: 2px">({{ totalRecord }})</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    ref="name"
                    v-model="searchParam.name"
                    type="text"
                    label="Name"
                    class="pt-0"
                    @keydown.enter="searchAllCustomers()"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <parent-auto-complete
                    ref="parent-company"
                    parent-text="Parent Company"
                    @event="selectedParent"
                  />
                </v-col>
                <v-col cols="12" md="4" class="mt-n3">
                  <account-manager-auto-complete
                    ref="account-manager"
                    color="#e1b753"
                    :default-manager="searchParam.manager"
                    @event="selectedAccountManager"
                    @blur="selectedAccountManager"
                  />
                </v-col>
                <v-col cols="12" md="4" class="pt-0">
                  <v-text-field
                    ref="city"
                    v-model="searchParam.city"
                    type="text"
                    label="City"
                    @keydown.enter="searchAllCustomers()"
                  />
                </v-col>
                <v-col cols="12" md="4" class="pt-0">
                  <v-text-field
                    ref="state"
                    v-model="searchParam.state"
                    type="text"
                    label="State"
                    hint="Short state name (Ex. Texas - TX)"
                    persistent-hint
                    @keydown.enter="searchAllCustomers()"
                  />
                </v-col>
                <v-col cols="12" md="4" class="mt-n3">
                  <agent-auto-complete
                    ref="account-owner"
                    agent-text="Account Owner"
                    @event="selectedAgent"
                  />
                </v-col>
                <v-col class="xs" cols="12" md="3" sm="4">
                  <v-btn
                    class="blue white--text dark-3"
                    small
                    @click="searchAllCustomers()"
                  >
                    <v-icon small> mdi-search-web </v-icon>
                    Filter
                  </v-btn>
                  <v-btn class="ml-1" small @click="resetSearch()">
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-col>
    <v-col cols="12">
      <v-container id="tblCustomers" fluid class="pt-0 data-container">
        <v-data-table
          ref="table"
          :headers="headers"
          :items="customerList"
          class="elevation-2 data-table"
          calculate-widths
          :loading="loadingIcon"
          fixed-header
          hide-default-footer
          :items-per-page="pageSize"
        >
          <template v-slot:item.name="{ item }">
            <router-link
              :to="{ name: 'Customer Detail', params: { id: item.id } }"
              class="name-link"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:item.city="{ item }">
            {{ formatLocation(item.city, item.state) }}
          </template>
          <template v-slot:item.totalRevenue="{ item }">
            ${{ item.totalRevenue }}
          </template>
          <template v-slot:footer>
            <v-page
              ref="table-footer"
              :total-row="totalRecord"
              :page-size-menu="[10, 20, 50, 100]"
              class="pt-3 pr-3 pb-3"
              @page-change="readDataFromAPI"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex"
import common from "@/utils/common"
import ParentAutoComplete from "@/views/pages/components/ParentAutoComplete"
import AgentAutoComplete from "@/views/pages/components/AgentAutoComplete"
import AccountManagerAutoComplete from "@/views/pages/components/AccountManagerAutoComplete"
export default {
  name: "Customers",
  components: {
    ParentAutoComplete,
    AgentAutoComplete,
    AccountManagerAutoComplete,
  },
  data() {
    return {
      searchParam: {
        name: null,
        company: null,
        city: null,
        state: null,
        accountOwner: null,
        parentId: null,
        agentId: null,
        manager: null,
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Parent Company", value: "parentDisplay", align: "start" },
        { text: "Location", value: "city", align: "start" },
        { text: "Shipments", value: "totalShipments", align: "center" },
        { text: "Total Revenue", value: "totalRevenue", align: "center" },
        {
          text: "Account Owner",
          value: "accountOwnerDisplay",
          align: "center",
        },
        {
          text: "Account Manager",
          value: "accountManagerDisplay",
          align: "center",
        },
      ],
      customerList: [],
      queryParams: {},
      totalRecord: 0,
      pageSize: 10,
      numberOfPages: 0,
      page: 1,
    }
  },
  computed: {
    ...mapGetters("generic", {
      loadingIcon: "loadingIcon",
      parentId: "parentId",
      agentId: "agentId",
    }),
    ...mapGetters("customer", {
      customerCompanies: "customerCompanies",
    }),
  },
  watch: {
    customerCompanies() {
      this.totalRecord = this.customerCompanies.count
      this.customerList = this.customerCompanies.results
      this.numberOfPages = Math.ceil(this.totalRecord / this.pageSize)
    },
  },
  mounted() {
    this.customerList = []
    this.getCustomers()
  },
  methods: {
    formatLocation: common.methods.formatLocation,
    getCustomers() {
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
    },
    resetSearch() {
      this.searchParam.name = null
      this.searchParam.company = null
      this.searchParam.city = null
      this.searchParam.state = null
      this.searchParam.accountOwner = null
      this.searchParam.parentId = null
      this.searchParam.agentId = null
      this.searchParam.manager = null
      this.$store.commit("generic/setParentId", null)
      this.customerList = []
      this.queryParams = {}
      this.queryParams.limit = 10
      this.queryParams.offset = 0
      this.$store.dispatch(
        "customer/GET_CUSTOMER_COMPANY_LIST",
        this.queryParams
      )
    },
    createQueryString() {
      this.queryParams = {}
      this.queryParams.limit = 20
      this.queryParams.offset = 0
      if (this.searchParam.name) {
        this.queryParams.name__icontains = this.searchParam.name
      }
      if (this.searchParam.parentId) {
        this.queryParams.parent = this.searchParam.parentId
      }
      if (this.searchParam.city) {
        this.queryParams.city__iexact = this.searchParam.city
      }
      if (this.searchParam.state) {
        this.queryParams.state__iexact = this.searchParam.state
      }
      if (this.searchParam.agentId) {
        this.queryParams.account_owner = this.searchParam.agentId
      }
      if (this.searchParam.manager) {
        this.queryParams.account_manager = this.searchParam.manager.id
      }
    },
    appendCustomerData() {
      const customerData = this.customerCompanies.results
      for (let i = 0; i < customerData.length; i++) {
        this.customerList.push(customerData[i])
      }
    },
    searchAllCustomers() {
      document
        .getElementById("tblCustomers")
        .removeEventListener("scroll", this.handleScroll, false)
      this.customerList = []
      this.createQueryString()
      this.$store.dispatch(
        "customer/GET_CUSTOMER_COMPANY_LIST",
        this.queryParams
      )
    },
    selectedParent(value) {
      if (value.parent) {
        if (value.flag === 1) {
          this.searchParam.parentId = value.parent.id
          this.searchAllCustomers()
        } else {
          this.searchParam.parentId = value.parent.id
        }
      }
    },
    selectedAgent(value) {
      if (value.agent) {
        if (value.flag === 1) {
          this.searchParam.agentId = value.agent.id
          this.searchAllCustomers()
        } else {
          this.searchParam.agentId = value.agent.id
        }
      }
    },
    readDataFromAPI(value) {
      this.page = value.pageNumber
      this.pageSize = value.pageSize
      let pageNumber = this.pageSize * this.page - this.pageSize
      if (pageNumber < 0) {
        pageNumber = 0
      }
      this.queryParams.offset = pageNumber
      this.queryParams.limit = this.pageSize
      this.$store.dispatch(
        "customer/GET_CUSTOMER_COMPANY_LIST",
        this.queryParams
      )
    },
    selectedAccountManager(value) {
      this.searchParam.manager = value.manager
    },
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .v-data-table-header th {
  background-color: #f6f6f6;
}
.v-data-table /deep/ .v-data-table__wrapper /deep/ .mdi-close-box {
  color: #ffa500 !important;
}
.name-link {
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.name-link:hover {
  text-decoration: none;
}
</style>
<style>
select {
  -webkit-appearance: auto;
  -moz-appearance: auto;
}
.v-pagination {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
</style>
