<template>
  <div class="backend-parent-autocomplete">
    <v-autocomplete
      v-model="parent"
      clearable
      :items="parentCustomerList"
      :loading="loading"
      :search-input.sync="search"
      color="info"
      item-text="name"
      item-value="id"
      :label="parentText"
      return-object
      close-on-content-click
      class="mt-n2"
      @keydown.enter="fireEnterEvent()"
      @blur="pushParentCustomer()"
      @click:clear="removeParentCustomer()"
    />
  </div>
</template>

<script>
import debounce from "lodash/debounce"
import api from "@/api/api"
import { mapGetters } from "vuex"
export default {
  name: "ParentAutoComplete",
  props: {
    parentText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectValue: null,
      loading: false,
      search: "",
      parentCustomers: [],
      isEnterEvent: false,
      selectedParent: null,
    }
  },
  computed: {
    ...mapGetters("generic", {
      parentId: "parentId",
    }),
    parentCustomerList() {
      return this.parentCustomers.map((parentCustomer) => {
        const name = parentCustomer.text
        return Object.assign({}, parentCustomer, { name })
      })
    },
    parent: {
      get() {
        return this.selectValue ? this.selectValue : null
      },
      set(newValue) {
        this.selectValue = newValue
        this.selectedParent = newValue
        if (this.isEnterEvent === true) {
          this.fireEnterEvent()
        }
      },
    },
  },
  watch: {
    search(val) {
      if (!val || val.length === 0) {
        this.parentCustomers = []
        return
      }
      if (this.parent) {
        if (val !== this.parent.name) {
          if (this.loading) return
          this.loading = true
          this.getSearch()
        }
      } else {
        if (this.loading) return
        this.loading = true
        this.getSearch()
      }
    },
    parentId() {
      if (this.parentId === 0 || this.parentId === null) {
        this.parent = {
          id: null,
          name: null,
          text: null,
        }
      }
    },
  },
  mounted() {
    this.parent = {
      id: null,
      name: null,
      text: null,
    }
    this.parentCustomers = [
      {
        id: null,
        name: null,
        text: null,
      },
    ]
  },
  methods: {
    getSearch: debounce(function () {
      api
        .get(
          `${process.env.VUE_APP_BASE_URL}/accounts/parent-customer-autocomplete/`,
          {
            params: {
              q: this.search,
            },
          }
        )
        .then((res) => {
          this.parentCustomers = res.data.results
          this.loading = false
        })
        .catch((err) => {
          const payload = {
            status: true,
            text: "Failed to retrieve parent customer.",
            error: err,
          }
          this.$store.commit("main/SET_SNACKBAR", payload)
          this.loading = false
        })
    }, 500),
    pushParentCustomer() {
      if (this.selectValue) {
        this.$store.commit("generic/setParentId", this.selectValue.id)
        this.$emit("event", { parent: this.selectValue, flag: 2 })
      }
    },
    removeParentCustomer() {
      this.parent = null
      this.$store.commit("generic/setParentId", null)
    },
    fireEnterEvent() {
      this.isEnterEvent = true
      if (
        this.selectedParent &&
        this.selectedParent.id !== undefined &&
        this.isEnterEvent === true
      ) {
        this.$emit("event", { parent: this.selectedParent, flag: 1 })
        this.isEnterEvent = false
        this.selectedParent = null
      }
    },
  },
}
</script>
